<template>
    <div class="page">
        <div class="page-header pd">
            <div>
                <sc-button icon="plus" @click="$router.push(`${resource.route}/new`)" v-if="$user.isAtLeast(1)">Add
                    {{ resource.name }}
                </sc-button>
            </div>
            <div class="search-wrap">
                <div class="search-type">
                    <select v-model="searchType" @change="search(query)">
                        <option value="both">Both</option>
                        <option value="groups">Groups</option>
                        <option value="players">Players</option>
                    </select>
                </div>
                <sc-search @searched="search"></sc-search>
            </div>
        </div>
        <sc-table :table="table" v-if="table" :loading="(player_groups.length === 0)?1:0">

            <template #rows>
                <template-playergroup v-for="group in player_groups" class="group" v-if="player_groups.length > 0"
                                      :key="group.id"
                                      :group="group"
                                      :canEdit="$user.isAtLeast(1)"
                                      :canDelete="$user.isAtLeast(1)"
                                      @edit="edit"
                                      @delete="deleting = $event"
                ></template-playergroup>
            </template>

        </sc-table>
        <sc-modal v-if="deleting" @close="deleting = null">
            <div class="deleting">
                <div class="info">
                    Do you want to delete the group '{{ deleting.attributes.name }}' and all of its children?
                </div>
                <div class="buttons" style="display:flex;justify-content: center;margin-top:2em;">
                    <sc-button style="margin-right:1em;" @click="destroy(deleting)">
                        Yes, delete
                    </sc-button>
                    <sc-button @click="deleting = null">
                        Cancel
                    </sc-button>
                </div>
            </div>
        </sc-modal>
    </div>

</template>

<script>
import ScSearch from "@/components/sc-search";
import TemplatePlayergroup from "@/views/app/templates/components/template-playergroup";

export default {
  name: "all",
  components: {TemplatePlayergroup, ScSearch},

  data() {
    return {
      table: null,
      player_groups: [],
      query: '',
      searchType: 'groups',
      deleting: null,
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      columns: {
        name: {
          width: 300,
          target: 'attributes.name',
          sort: false,
        },
      },
      hover: false,
    });

    this.fetch();
  },

  methods: {

    fetch() {
      this.$talker.api('player-groups?players=true')
        .then(res => {
          this.player_groups = this.getGroupsTree(res.data.data)
        })
        .catch(err => {
          this.$notify.error('Failed to get player groups')
        })
    },

    getGroupsTree(_groups) {
      let ids = _groups.flatMap(x => x.id)
      let parents = _groups.filter(x => !ids.includes(x.attributes.parent_id))
      return parents.map(x => this.getGroupTree(_groups, x))
    },

    getGroupTree(_groups, _parent = {id: null}) {
      _parent['open'] = false;
      _parent['filtered'] = false;
      _parent['children'] = _groups.filter(x => x.attributes.parent_id === _parent.id)
      _parent['children'].map(x => this.getGroupTree(_groups, x))
      return _parent;
    },

    search(search) {
      this.query = search;

      if (this.query.length === 0) {
        this.clearFilterAll(this.player_groups)
      } else {
        this.runFilter(this.query, this.player_groups);
      }
    },

    runFilter(search, groups, callback) {

      let query = search.toLowerCase();
      let queryType = this.searchType;

      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];

        group.filtered = false;
        group.open = false;

        if (queryType === 'groups' || queryType === 'both') {
          let groupName = group.attributes.name.toLowerCase();
          if (groupName.indexOf(query) !== -1) {
            group.filtered = true;
            this.searchCount++;
            if (typeof callback === "function") {
              callback();
            }
          }
        }


        if (group.players) {
          for (let p = 0; p < group.players.data.length; p++) {
            let player = group.players.data[p];
            player.filtered = false;
            if (queryType === 'players' || queryType === 'both') {
              let playerName = player.attributes.name.toLowerCase();
              if (playerName.indexOf(query) !== -1) {
                this.searchCount++;
                player.filtered = true;
                group.open = true;
                if (typeof callback === "function") {
                  callback();
                }
              }
            }
          }
        }


        if (group.children && group.children.length > 0) {
          this.runFilter(search, group.children, () => {
            group.open = true;
            if (typeof callback === "function") {
              callback();
            }
          });
        }
      }
    },

    clearFilterAll(groups) {
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];

        group.filtered = false;
        group.open = false;

        if (group.players) {
          for (let p = 0; p < group.players.data.length; p++) {
            let player = group.players.data[p];
            player.filtered = false;
          }
        }

        if (group.children && group.children.length > 0) {
          this.clearFilterAll(group.children);
        }
      }
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.player_groups = [];
          this.deleting = null;
          this.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${this.resource.route}/${row.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.group {
    padding: 0.5em 0 0.5em 2em;
    border-bottom: 1px solid #eee;

    &:last-child {
        border: none;
    }
}

.search-wrap {
    height: 2.5em;
    display: flex;

    .search {
        flex: 1;
        margin-left: 1em;
    }

    select {
        padding: 0.85em;
        box-sizing: border-box;
        border: none;
        box-shadow: $shading;
    }
}
</style>